@font-face {
  font-family: 'BradfordMonoLL-Regular';
  src: local('BradfordMonoLL-Regular'), url('./fonts/BradfordMonoLL-Regular.woff2') format('woff2'),
    url('./fonts/BradfordMonoLL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BradfordLL-Regular';
  src: url('./fonts/BradfordLL-Regular.woff2') format('woff2'),
    url('./fonts/BradfordLL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BradfordLL-Italic';
  src: url('./fonts/BradfordLL-Italic.woff2') format('woff2'),
    url('./fonts/BradfordLL-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'BradfordLL-Bold';
  src: url('./fonts/BradfordLL-Bold.woff2') format('woff2'),
    url('./fonts/BradfordLL-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 10px;
  line-height: 1.2;
  font-family: 'BradfordLL-Regular', serif;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'BradfordLL-Regular', serif;
  overflow-x: hidden;
}

@media (min-width: 770px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #aeadad;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  body {
    scrollbar-width: thin;
  }
}

.ant-layout {
  background: #ffffff;
}
